import ViewportStore from '@/state/ViewportStore';
import {copyToClipboardWithStatusContext} from '@/utils/clipboard';
import {Popover, PopoverButton, PopoverPanel, Transition} from '@headlessui/react';
import clsx from 'clsx';
import {observer} from 'mobx-react-lite';
import React, {
  forwardRef,
  useCallback,
  useId,
  useRef,
  type ComponentProps,
  type ForwardedRef,
} from 'react';

type Props = {
  className?: string;
  children: React.ReactNode;
  anchor?: ComponentProps<typeof PopoverPanel>['anchor'];
  contentClassName?: string;
} & XOR<
  {
    content: React.ReactNode;
  },
  {
    Component: React.ComponentType<any>;
    componentProps?: Record<string, any>;
  }
>;

export const LivanTooltip = observer(
  forwardRef(function LivanTooltip(props: Props, ref: any) {
    const {className, children, content, anchor, contentClassName, Component, componentProps} =
      props;
    const triggerRef = useRef<HTMLButtonElement>(ref);

    const anchorObj: ComponentProps<typeof PopoverPanel>['anchor'] = {
      gap: 10,
    };
    if (typeof anchor === 'string') {
      anchorObj.to = anchor;
    } else {
      Object.assign(anchorObj, anchor);
    }

    if (!ViewportStore.md && (anchorObj.to?.includes('left') || anchorObj.to?.includes('right'))) {
      // override this on small viewports so that tooltips don't overflow and get scrunched
      anchorObj.to = 'bottom';
    }

    const handleEnter = (isOpen) => {
      if (!isOpen) {
        triggerRef.current?.click();
      }
    };

    const handleLeave = (isOpen) => {
      if (isOpen) {
        triggerRef.current?.click();
      }
    };
    const handlePanelClick = useCallback(
      function () {
        if (typeof content === 'string') {
          copyToClipboardWithStatusContext(content);
        }
      },
      [content],
    );

    const outsideClickScope = useId();
    const tooltipId = useId();

    return (
      <Popover
        as={React.Fragment}
        outsideClickScope={outsideClickScope}
      >
        {({open}) => {
          return (
            <React.Fragment>
              <PopoverButton
                as="span"
                aria-describedby={tooltipId}
                className={clsx('TooltipTrigger', className)}
                ref={triggerRef}
                onMouseEnter={() => {
                  return handleEnter(open);
                }}
                onMouseLeave={() => {
                  return handleLeave(open);
                }}
              >
                {children}
              </PopoverButton>
              <Transition
                as={React.Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <PopoverPanel
                  id={tooltipId}
                  role="tooltip"
                  className={clsx(
                    contentClassName,
                    'absolute z-50 px-2 py-1 bg-livan-black text-livan-white rounded-md text-sm !max-w-64',
                    typeof content === 'string' && 'cursor-pointer',
                  )}
                  anchor={anchorObj}
                  portal
                  onClick={typeof content === 'string' ? handlePanelClick : undefined}
                >
                  {Component ? <Component {...componentProps} /> : content}
                </PopoverPanel>
              </Transition>
            </React.Fragment>
          );
        }}
      </Popover>
    );
  }),
);
